import { lazy } from 'react';
import { ContactUs, PrivacyPolicy } from '../screens';
import Protected from './protected';

const Navbar = lazy(() => import('../components/Navbar'));
const Footer = lazy(() => import('../components/Footer'));
const Landing = lazy(() => import('../screens/Landing'));
const Login = lazy(() => import('../screens/Login/Views/Main'));
const About = lazy(() => import('../screens/About/Views/Main'));
const Services = lazy(() => import('../screens/About/Views/Services'));
const AboutDetail = lazy(() => import('../screens/About/Views/Detail'));
const News = lazy(() => import('../screens/News/Main'));
const NewsDetail = lazy(() => import('../screens/News/Detail'));
// const UserGuide = lazy(() => import('../screens/UserGuide/Main'));
const UserGuideDetail = lazy(() => import('../screens/UserGuide/Detail'));
const PageNotFound = lazy(() => import('../screens/PageNotFound'));
const Layout = lazy(() => import('../screens/CMS'));
const CMSNewsList = lazy(() => import('../screens/CMS/News/List'));
const CMSNewsCreate = lazy(() => import('../screens/CMS/News/Create'));
const CMSNewsEdit = lazy(() => import('../screens/CMS/News/Edit'));
const CMSNewsCategoryList = lazy(() => import('../screens/CMS/News/Category/List'));
const CMSNewsCategoryCreate = lazy(() => import('../screens/CMS/News/Category/Create'));
const CMSNewsCategoryEdit = lazy(() => import('../screens/CMS/News/Category/Edit'));
const ProductList = lazy(() => import('../screens/CMS/ProductList'));
// const Register = lazy(() => import('../screens/Register'));
const RegisterInternal = lazy(() => import('../screens/RegisterInternal'));
// const Faq = lazy(() => import('../screens/Faq'));
const AddProduct = lazy(() => import('../screens/CMS/ProductCreate'));
const EditProduct = lazy(() => import('../screens/CMS/ProductEdit'));
const ProductCategoryList = lazy(() => import('../screens/CMS/ProductCategoryList'));
const ProductCategoryCreate = lazy(() => import('../screens/CMS/ProductCategoryCreate'));
const ForgotPassword = lazy(() => import('../screens/ForgotPassword'));
const ResetPassword = lazy(() => import('../screens/ResetPassword'));
const Activation = lazy(() => import('../screens/Activation'));
const CMSPartnerList = lazy(() => import('../screens/CMS/Partner/List'));
const CMSPartnerCreate = lazy(() => import('../screens/CMS/Partner/Create'));
const CMSPartnerEdit = lazy(() => import('../screens/CMS/Partner/Edit'));
const CMSInformationForm = lazy(() => import('../screens/CMS/News/Information/Form'));
const LoginOtp = lazy(() => import('../screens/Login/Views/Otp'));
const LoginVerify = lazy(() => import('../screens/Login/Views/Verify'));
const CMS_READ_NEWS = 'cms.read.news';
const CMS_READ_PRODUCT = 'cms.read.product';
const TermsAndConditions = lazy(() => import('../screens/TnC/Landing'));
const CMSTnCList = lazy(() => import('../screens/CMS/TnC/List'));
const CMSTnCCreate = lazy(() => import('../screens/CMS/TnC/Create'));
const CMSTnCEdit = lazy(() => import('../screens/CMS/TnC/Edit'));
const CMSPrivacyPolicyList = lazy(() => import('../screens/CMS/PrivacyPolicy/List'));
const CMSPrivacyPolicyCreate = lazy(() => import('../screens/CMS/PrivacyPolicy/Create'));
const CMSPrivacyPolicyEdit = lazy(() => import('../screens/CMS/PrivacyPolicy/Edit'));

const getElement = (element, withHeader = true, withFooter = true) => (
  <>
    {withHeader && <Navbar />}
    {element}
    {withFooter && <Footer />}
  </>
);

const getProtectedElement = (element, permission) => (
  <Protected permission={permission}>{element}</Protected>
);

const landing = [
  {
    path: '/',
    element: getElement(<Landing />)
  },
  {
    path: 'login',
    element: getElement(<Login />, true, false)
  },
  {
    path: 'login/verify',
    element: getElement(<LoginOtp />, true, false)
  },
  {
    path: 'login/verify/otp',
    element: getElement(<LoginVerify />, true, false)
  },
  {
    path: 'cms/login/verify',
    element: getElement(<LoginOtp />, true, false)
  },
  {
    path: 'cms/login/verify/otp',
    element: getElement(<LoginVerify />, true, false)
  },
  // {
  //   path: 'register',
  //   element: getElement(<Register />, true, false)
  // },
  {
    path: 'registration-internal',
    element: getElement(<RegisterInternal />, true, false)
  },
  {
    path: 'cms/login',
    element: getElement(<Login />, true, false)
  },
  // {
  //   path: 'faq',
  //   element: getElement(<Faq />)
  // },
  {
    path: 'about',
    element: getElement(<About />)
  },
  {
    path: `about/services`,
    element: getElement(<Services />)
  },
  {
    path: `about/:aboutId`,
    element: getElement(<AboutDetail />)
  },
  {
    path: `contact`,
    element: getElement(<ContactUs />)
  },
  {
    path: `about/:aboutId`,
    element: getElement(<AboutDetail />)
  },
  {
    path: 'news',
    element: getElement(<News />)
  },
  {
    path: 'news/:id',
    element: getElement(<NewsDetail />)
  },
  // {
  //   path: 'guides',
  //   element: getElement(<UserGuide />)
  // },
  {
    path: 'forgot-password',
    element: getElement(<ForgotPassword />, true, false)
  },
  {
    path: 'reset-password/:uuid',
    element: getElement(<ResetPassword />, true, false)
  },
  {
    path: 'activation',
    element: getElement(<Activation />, true, false)
  },
  {
    path: 'guides/:types',
    element: getElement(<UserGuideDetail />)
  },
  {
    path: 'terms-and-conditions',
    element: getElement(<TermsAndConditions />)
  },
  {
    path: 'privacy-policy',
    element: getElement(<PrivacyPolicy />)
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

const cms = [
  {
    path: 'cms',
    element: getProtectedElement(<Layout />, 'cms.read.dashboard'),
    children: [
      {
        path: 'home',
        element: getProtectedElement(<>dashboard</>, 'cms.read.dashboard')
      },
      {
        path: 'news',
        element: getProtectedElement(<CMSNewsList />, CMS_READ_NEWS)
      },
      {
        path: 'news/landing-page',
        element: getProtectedElement(<CMSNewsList tab="landing-page" />, CMS_READ_NEWS)
      },
      {
        path: 'news/landing-page/:id/edit',
        element: getProtectedElement(<CMSNewsEdit />, CMS_READ_NEWS)
      },
      {
        path: 'news/landing-page/create',
        element: getProtectedElement(<CMSNewsCreate />, CMS_READ_NEWS)
      },
      {
        path: 'news/landing-page/:id/edit',
        element: getProtectedElement(<CMSNewsEdit />, CMS_READ_NEWS)
      },
      {
        path: 'news/landing-page/create',
        element: getProtectedElement(<CMSNewsCreate />, CMS_READ_NEWS)
      },
      {
        path: 'news/landing-page/edit',
        element: getProtectedElement(<CMSNewsEdit />, CMS_READ_NEWS)
      },
      {
        path: 'news-categories',
        element: getProtectedElement(<CMSNewsCategoryList />, CMS_READ_NEWS)
      },
      {
        path: 'news-categories/create',
        element: getProtectedElement(<CMSNewsCategoryCreate />, CMS_READ_NEWS)
      },
      {
        path: 'news-categories/:id/edit',
        element: getProtectedElement(<CMSNewsCategoryEdit />, CMS_READ_NEWS)
      },
      {
        path: 'product',
        element: getProtectedElement(<ProductList />, CMS_READ_PRODUCT)
      },
      {
        path: 'product-categories',
        element: getProtectedElement(<ProductCategoryList />, CMS_READ_PRODUCT)
      },
      {
        path: 'product-categories/create',
        element: getProtectedElement(<ProductCategoryCreate />, CMS_READ_PRODUCT)
      },
      {
        path: 'product-categories/:id/edit',
        element: getProtectedElement(<ProductCategoryCreate />, CMS_READ_PRODUCT)
      },
      {
        path: 'product/:id',
        element: <>product detail</>
      },
      {
        path: 'product/create',
        element: getProtectedElement(<AddProduct />, CMS_READ_PRODUCT)
      },
      {
        path: 'product/:id/edit',
        element: getProtectedElement(<EditProduct />, CMS_READ_PRODUCT)
      },
      {
        path: 'partners',
        element: getProtectedElement(<CMSPartnerList />, 'cms.read.partner')
      },
      {
        path: 'partners/create',
        element: getProtectedElement(<CMSPartnerCreate />, 'cms.create.partner')
      },
      {
        path: 'partners/:id/edit',
        element: getProtectedElement(<CMSPartnerEdit />, 'cms.update.partner')
      },
      {
        path: 'news/dashboard',
        element: getProtectedElement(<CMSNewsList tab="dashboard" />, 'cms.read.information')
      },
      {
        path: 'news/dashboard/create',
        element: getProtectedElement(
          <CMSInformationForm isEdit={false} />,
          'cms.create.information'
        )
      },
      {
        path: 'news/dashboard/:id/edit',
        element: getProtectedElement(<CMSInformationForm isEdit={true} />, 'cms.update.information')
      },
      {
        path: 'terms-and-conditions',
        element: getProtectedElement(<CMSTnCList />, 'cms.read.dashboard')
      },
      {
        path: 'terms-and-conditions/create',
        element: getProtectedElement(<CMSTnCCreate />, 'cms.read.dashboard')
      },
      {
        path: 'terms-and-conditions/:id/edit',
        element: getProtectedElement(<CMSTnCEdit />, 'cms.read.dashboard')
      },
      {
        path: 'privacy-policy',
        element: getProtectedElement(<CMSPrivacyPolicyList />, 'cms.read.dashboard')
      },
      {
        path: 'privacy-policy/create',
        element: getProtectedElement(<CMSPrivacyPolicyCreate />, 'cms.read.dashboard')
      },
      {
        path: 'privacy-policy/:id/edit',
        element: getProtectedElement(<CMSPrivacyPolicyEdit />, 'cms.read.dashboard')
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export { landing, cms };
