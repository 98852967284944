import {
  landing1,
  logo,
  landingIcon1,
  landingIcon2,
  landingIcon3,
  landingIcon4
} from '../../assets';
import { GradientImage } from '../../components';
import './index.css';
import { Col, Image, Row } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { shorthand } from '../../utils';
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const { t } = useTranslation();
  return (
    <div>
      <GradientImage
        image={landing1}
        caption={'Digital Partnership'}
        description={t('HOME-001')}
        button={{
          text: t('LANG-006'),
          action: () => {
            location.href = '/login';
          }
        }}
      />
      <div
        data-aos="slide-up"
        data-aos-duration={shorthand.aosDuration}
        className="d-flex flex-column align-items-center justify-content-center mx-auto py-5 landing-apps-container-width">
        <Image src={logo} className="my-3" width={300} />
        <div className="landing-apps-description my-3">{t('HOME-003')}</div>
        <a href="/about" className="text-red my-3">
          {t('HOME-004')} <FaChevronRight className="align-self-center" />
        </a>
      </div>

      {/* {[
        {
          image: landing2,
          title: t('HOME-005'),
          description: t('HOME-006'),
          button: t('HOME-007'),
          directTo: 'about/services'
        },
        {
          image: landing3,
          title: t('HOME-008'),
          description: t('HOME-009'),
          button: t('HOME-010'),
          directTo: 'guides/partnership-steps'
        }
      ].map((x, i) => (
        <Row key={i.toString()} className="landing-apps-content-margin">
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <div data-aos="fade-right" data-aos-duration={shorthand.aosDuration}>
              <Image src={x.image} fluid rounded />
            </div>
          </Col>
          <Col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
            <div
              className="d-flex flex-column justify-content-center h-100 mx-xl-5"
              data-aos="slide-up"
              data-aos-duration={shorthand.aosDuration}>
              <h1 className="mb-3">{x.title}</h1>
              <div className="mb-3">{x.description}</div>
              <a href={`/${x.directTo}`} className="text-red">
                {x.button} <FaChevronRight className="align-self-center" />
              </a>
            </div>
          </Col>
        </Row>
      ))} */}

      <Row className="landing-apps-content-margin">
        <Col>
          <div data-aos="slide-up" data-aos-duration={shorthand.aosDuration}>
            <h1>{t('HOME-011')}</h1>
            <p>{t('HOME-012')}</p>
          </div>
        </Col>
      </Row>
      <Row className="landing-apps-content-margin">
        {[
          {
            image: landingIcon1,
            caption: t('HOME-013'),
            description: t('HOME-014')
          },
          {
            image: landingIcon2,
            caption: t('HOME-015'),
            description: t('HOME-016')
          },
          {
            image: landingIcon3,
            caption: t('HOME-017'),
            description: t('HOME-018')
          },
          {
            image: landingIcon4,
            caption: t('HOME-019'),
            description: t('HOME-020')
          }
        ].map((x, i) => (
          <Col
            key={i.toString()}
            xxl="3"
            xl="3"
            lg="3"
            md="12"
            sm="12"
            xs="12"
            data-aos="slide-up"
            data-aos-duration={shorthand.aosDuration}>
            <div className="text-center mx-3 mb-md-5">
              <Image src={x.image} fluid />
              <h3 className="my-3">{x.caption}</h3>
              <p>{x.description}</p>
            </div>
          </Col>
        ))}
      </Row>
      {/* <Row className="landing-apps-content-margin my-5">
        <Col>
          <div data-aos="slide-up" data-aos-duration={shorthand.aosDuration}>
            <h1>{t('HOME-021')}</h1>
            <p>{t('HOME-022')}</p>
          </div>
        </Col>
      </Row>
      <Row className="landing-apps-content-margin">
        {[
          {
            image: landingPartner1,
            link: 'https://www.telkomsel.com/'
          },
          {
            image: landingPartner2,
            link: 'https://bri.co.id/'
          },
          {
            image: landingPartner3,
            link: ''
          },
          {
            image: landingPartner4,
            link: ''
          },
          {
            image: landingPartner5,
            link: ''
          },
          {
            image: landingPartner6,
            link: ''
          },
          {
            image: landingPartner7,
            link: ''
          },
          {
            image: landingPartner8,
            link: ''
          },
          {
            image: landingPartner9,
            link: ''
          },
          {
            image: landingPartner10,
            link: ''
          },
          {
            image: landingPartner11,
            link: ''
          },
          {
            image: landingPartner12,
            link: ''
          }
        ].map((x, i) => (
          <Col key={i.toString()} xxl="2" xl="2" lg="2" md="6" sm="6" xs="6">
            <div
              className="text-center"
              data-aos="fade-right"
              data-aos-duration={shorthand.aosDuration}>
              <a href={x.link} target="_blank" rel="noreferrer">
                <Image src={x.image} fluid />
              </a>
            </div>
          </Col>
        ))}
      </Row> */}
      {/* <div
        className="image-cover-fluid"
        style={{ '--img': `url(${landing4})` }}
        data-aos="slide-up"
        data-aos-duration={shorthand.aosDuration}>
        <div className="image-overlay">
          <Row className="mb-5">
            <Col>
              <div data-aos="fade-in" data-aos-delay="200">
                <h1 className="text-white">{t('HOME-023')}</h1>
                <p className="text-white">{t('HOME-024')}</p>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            {[
              {
                caption: t('HOME-025'),
                description: t('HOME-026')
              },
              {
                caption: t('HOME-027'),
                description: t('HOME-028')
              },
              {
                caption: t('HOME-029'),
                description: t('HOME-030')
              }
            ].map((x, i) => (
              <Col key={i.toString()} xxl="4" xl="4" lg="4" md="12" sm="12" xs="12">
                <Row>
                  <Col
                    xxl="1"
                    xl="1"
                    lg="1"
                    md="1"
                    sm="1"
                    xs="2"
                    data-aos="fade-in"
                    data-aos-delay="200">
                    <h4 className="text-white">
                      <span className="badge-banner badge rounded-pill">{i + 1}</span>
                    </h4>
                  </Col>
                  <Col
                    xxl="11"
                    xl="11"
                    lg="11"
                    md="11"
                    sm="11"
                    xs="10"
                    data-aos="fade-in"
                    data-aos-delay="200"
                    data-aos-duration={shorthand.aosDuration}>
                    <h1 className="text-white">{x.caption}</h1>
                    <p className="text-white">{x.description}</p>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Row>
            <Col
              className="text-center"
              data-aos="fade-in"
              data-aos-delay="200"
              data-aos-duration={shorthand.aosDuration}>
              <Button
                variant="danger"
                onClick={() => (location.href = '/register')}
                size="lg"
                className="d-flex justify-content-center banner-button m-auto">
                {t('HOME-031')} <FaChevronRight className="align-self-center m-1" />
              </Button>
            </Col>
          </Row>
        </div>
      </div> */}
    </div>
  );
};

export default Landing;
