import React from 'react';
import { Container, Nav, NavDropdown, Navbar as NB, Button, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { logo, globe } from '../../assets';
import { lang } from '../../i18n';
import { generalAction } from '../../stores/actions';
import './index.css';
import { FaChevronDown } from 'react-icons/fa';
import { BsCheckLg } from 'react-icons/bs';

const Navbar = () => {
  const general = useSelector((state) => state.generalReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <NB bg="white" expand="lg" sticky="top" className="shadow-sm navbar-body">
        <Container>
          <NB.Brand href="/">
            <Image src={logo} alt="logo" />
          </NB.Brand>
          <NB.Toggle aria-controls="basic-navbar-nav" />
          <NB.Collapse id="basic-navbar-nav">
            <Nav className="ms-lg-5 me-lg-auto">
              <Nav.Link href="/" className="text-navbar-item">
                {t('LANG-001')}
              </Nav.Link>
              <NavDropdown
                title={
                  <span>
                    {t('LANG-002')}
                    <FaChevronDown className="ms-1" size={12} />
                  </span>
                }
                id="about"
                className="text-navbar-item">
                <NavDropdown.Item href="/about">
                  <span>{t('LANG-008')}</span>
                  <br />
                  <span className="text-secondary">{t('LANG-016')}</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/about/services">
                  <span>{t('LANG-009')}</span>
                  <br />
                  <span className="text-secondary">{t('LANG-017')}</span>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/news" className="text-navbar-item">
                {t('LANG-003')}
              </Nav.Link>
              {/* <NavDropdown
                title={
                  <span>
                    {t('LANG-004')}
                    <FaChevronDown className="ms-1" size={12} />
                  </span>
                }
                className="text-navbar-item"
                id="help">
                <NavDropdown.Item href="/faq">
                  <span>{t('LANG-010')}</span>
                  <br />
                  <span className="text-secondary">{t('LANG-018')}</span>
                </NavDropdown.Item>
                <NavDropdown.Item href="/guides">
                  <span>{t('LANG-011')}</span>
                  <br />
                  <span className="text-secondary">{t('LANG-019')}</span>
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link href="/contact" className="text-navbar-item">
                {t('LANG-005')}
              </Nav.Link>
            </Nav>
            <Nav className="justify-content-end me-4">
              <NavDropdown
                title={
                  <span>
                    <img src={globe} alt="logo" className="m-1" />
                    {general.language?.short}
                    <FaChevronDown className="ms-1" size={12} />
                  </span>
                }
                id="language">
                {lang.map((x) => (
                  <NavDropdown.Item
                    key={x.code}
                    className="d-flex gap-2 align-items-center"
                    onClick={() => dispatch(generalAction.changeLanguage(x))}>
                    {x.long}
                    {general.language.code === x.code && <BsCheckLg color="#E42313" />}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
            <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-xs-column gap-4 p-2 p-lg-0">
              <Button
                as="a"
                href="/login"
                variant="outline-secondary"
                size="sm"
                className="text-button-navbar login">
                {t('LANG-006')}
              </Button>
              {/* <Button
                as="a"
                href="/register"
                variant="outline-danger"
                size="sm"
                className="text-button-navbar register">
                {t('LANG-007')}
              </Button> */}
            </div>
          </NB.Collapse>
        </Container>
      </NB>
    </>
  );
};

export default Navbar;
